@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:300|Lato:300,400,900);
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-color: #121a24;
}
.layerContainer {
  position: relative; padding: 0;
  margin: auto; text-align: center;
}

.imageLayer {
  position: absolute;
  left: 0px;
}
